/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCleanFilename } from 'helpers/mediaUtils'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type {
  Devis,
  Facture,
  JalonSimplifie,
} from 'API/__generated__/Api'
import {
  EtatFacture,
  EtatDevis,
  WorkflowEtat,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface ChipProps {
  textColor: string;
  backgroundColor: string;
}

const Chip = styled(ColoredSquareChip)<ChipProps>`
  height: 100%;
  min-height: 37px;
  min-width: 160px;
  white-space: pre-line;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-weight: bold;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowJalonCardProps {
  jalon: JalonSimplifie;
  quote?: Devis | null;
  invoice?: Facture | null;
  comment?: string;
}

const CaseWorkflowJalonCard: React.FC<CaseWorkflowJalonCardProps> = ({
  jalon,
  quote,
  invoice,
  comment,
}) => {
  const navigate = useNavigate()
  const isReadOnly = useIsReadOnly()

  const getButton = () => {
    switch (jalon.type.code) {
      case WorkflowJalon.Devis:
      case WorkflowJalon.DevisHorsPriseEnCharge:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate(`nouveau-devis${jalon.type.code === WorkflowJalon.DevisHorsPriseEnCharge ? '-hors-prise-en-charge' : ''}`)}
              disabled={isReadOnly}
            >
              Nouveau devis
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (jalon.etat === WorkflowEtat.Brouillon || jalon.etat === WorkflowEtat.Annule) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate(`devis${jalon.type.code === WorkflowJalon.DevisHorsPriseEnCharge ? '-hors-prise-en-charge' : ''}`)}
              disabled={isReadOnly}
            >
              {`${jalon.etat === WorkflowEtat.Brouillon ? 'Continuer' : 'Modifier'} le devis`}
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (quote?.etat?.code === EtatDevis.Valide || quote?.etat?.code === EtatDevis.Regle || quote?.etat?.code === EtatDevis.Transmis || quote?.etat?.code === EtatDevis.Retransmis) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate(`visualisation-devis${jalon.type.code === WorkflowJalon.DevisHorsPriseEnCharge ? '-hors-prise-en-charge' : ''}`)}
              disabled={isReadOnly}
            >
              Voir le devis
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      case WorkflowJalon.Facture:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('nouvelle-facture')}
              disabled={isReadOnly}
            >
              Nouvelle facture
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (jalon.etat === WorkflowEtat.Brouillon || jalon.etat === WorkflowEtat.Annule) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('facture')}
              disabled={isReadOnly}
            >
              {`${jalon.etat === WorkflowEtat.Brouillon ? 'Continuer' : 'Modifier'} la facture`}
            </CaseWorkflowStyledComponents.Button>
          )
        }
        if (invoice?.etat?.code === EtatFacture.Valide || invoice?.etat?.code === EtatFacture.Regle || invoice?.etat?.code === EtatFacture.Envoye || invoice?.etat?.code === EtatFacture.Renvoye) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('visualisation-facture')}
              disabled={isReadOnly}
            >
              Voir la facture
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      case WorkflowJalon.CompteRenduVisite:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('compte-rendu-sinapps')}
              disabled={isReadOnly}
            >
              Déposer le compte-rendu
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      case WorkflowJalon.CompteRenduRDF:
        if (jalon.etat === WorkflowEtat.EnAttente) {
          return (
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('compte-rendu-sinapps-rdf')}
              disabled={isReadOnly}
            >
              Déposer le rapport RDF
            </CaseWorkflowStyledComponents.Button>
          )
        }
        break
      default:
        break
    }
    if (jalon.pieceJointe) {
      return (
        <CaseWorkflowDownloadButton
          name={getCleanFilename(jalon.pieceJointe?.libelle || '', jalon.pieceJointe?.fileName || '')}
          url={jalon.pieceJointe?.url || ''}
          id={jalon.pieceJointe.id || ''}
        />
      )
    }
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          {
            quote?.statut?.libelle ?
              <Chip
                color="green"
                backgroundColor={quote.statut.couleurFond || ''}
                textColor={quote.statut.couleurPolice || ''}
              >
                {`Devis ${quote?.statut?.libelle}`}
              </Chip> :
              invoice?.statut?.libelle ?
                <Chip
                  color="green"
                  backgroundColor={invoice.statut.couleurFond || ''}
                  textColor={invoice.statut.couleurPolice || ''}
                >
                  {`Facture ${invoice?.statut?.libelle}`}
                </Chip> :
                jalon.libelle ?? jalon.type.libelle
          }
        </CaseWorkflowStyledComponents.Title>
        {getButton() ?? <div />}
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.date}
          state={jalon.etat}
          quoteState={quote?.etat?.code}
          invoiceState={invoice?.etat?.code}
          comment={comment}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowJalonCard
