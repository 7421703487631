/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostWorksRdvMutation,
  usePatchWorksRdvMutation,
  useGetSinappsSelectListQuery,
} from 'store/api'
import {
  isApiError,
  type ApiResponse,
} from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import {
  Alert,
  AlertTitle,
  Dialog,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import { Field } from 'formik'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  RendezVousTravaux,
  RendezVousTravauxUpdateRequest,
} from 'API/__generated__/Api'
import {
  EnumerationsMetier,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type RdvRequest = RendezVousTravauxUpdateRequest & {
  isEdit: boolean;
  isSinapps: boolean;
}

const rdvSchema = Yup.object().shape<Shape<RdvRequest>>({
  isSinapps: Yup.boolean(),
  dateDebut: Yup.string().nullable()
    .test('dateDebut',
      'La date de début doit être supérieur à maintenant',
      (dateDebut, { parent }: {parent: RdvRequest}) => parent.isSinapps ? new Date(dateDebut || '') > new Date() : true,
    ).required('La date de début est obligatoire'),
  dateFin: Yup.string().nullable()
    .test('dateFin',
      'La date de fin ne peut pas être inférieure à celle de début',
      (dateFin = '', { parent }: {parent: RdvRequest}) => {
        const dates = { dateDebut: parent.dateDebut, dateFin: dateFin || '' }
        return new Date(dates.dateFin) > new Date(dates.dateDebut)
      },
    ).required('La date de fin est obligatoire'),
  isEdit: Yup.boolean(),
  commentaire: Yup.string().when('isEdit', {
    is: true,
    then: (schema) => schema.required('Le commentaire est obligatoire'),
  }),
  motif: Yup.string().when('isEdit', {
    is: true,
    then: (schema) => schema.required('Le motif est obligatoire'),
  }),
}).required()

type RdvForm = FormikContextType<RdvRequest>

/* Internal variables ------------------------------------------------------- */
const initialDay: RdvRequest = {
  dateDebut: new Date().toISOString(),
  dateFin: new Date().toISOString(),
  isEdit: false,
  isSinapps: false,
}

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const StyledAlert = styled(Alert)`
  margin-top: 20px;
`

const StyledAlertTitle = styled(AlertTitle)`
  margin-bottom: 0px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorksRdvButtonProps {
  caseId: string;
  initialRdv?: RendezVousTravaux;
  state: WorkflowEtat;
  warning: string;
  isSinapps: boolean;
}

const CaseWorkflowWorksRdvButton: React.FC<CaseWorkflowWorksRdvButtonProps> = ({
  caseId,
  initialRdv,
  state,
  warning,
  isSinapps,
}) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewRdv,
  ] = usePostWorksRdvMutation()
  const [
    submitEditRdv,
  ] = usePatchWorksRdvMutation()
  const {
    currentData: reasonList = [],
    isFetching: isFetchingReasonList,
  } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.MotifModificationPlanification })

  const onSubmit = (values: RdvRequest, { setSubmitting, resetForm }: FormikHelpers<RdvRequest>) => {
    const onFinish = (response: ApiResponse<void>) => {
      if (!isApiError(response)) {
        setOpen(false)
        setSubmitting(false)
        resetForm()
      } else {
        setSubmitting(false)
      }
    }

    if (initialRdv) {
      submitEditRdv({ id: initialRdv.id, data: values }).then(onFinish).catch(console.error)
    } else {
      submitNewRdv({ caseId, data: values }).then(onFinish).catch(console.error)
    }
  }

  const formikForm: RdvForm = useForm<RdvRequest>(
    {
      initialValues: initialDay,
      onSubmit: onSubmit,
      validationSchema: rdvSchema,
    },
  )

  useEffect(() => {
    formikForm.setFieldValue('isSinapps', isSinapps)
  }, [ isSinapps ])

  useEffect(() => {
    if (!initialRdv) return
    formikForm.setValues({ ...initialRdv, isEdit: true, commentaire: '', motif: '', isSinapps })
  }, [ initialRdv ])

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {state === WorkflowEtat.Fait ? 'Modifier les RDV' : state === WorkflowEtat.EnAttente ? 'Nouveaux RDV travaux' : 'Reprendre des RDV travaux'}
      </CaseWorkflowStyledComponents.Button>
      {
        open &&
          <Dialog
            open
            onClose={onClose}
            maxWidth="xl"
          >
            <CaseWorkflowStyledComponents.DialogTitle>
              {state === WorkflowEtat.Fait ? 'Modifier les RDV' : state === WorkflowEtat.EnAttente ? 'Nouveaux RDV travaux' : 'Reprendre des RDV travaux'}
              <CloseButton handleClose={onClose} />
            </CaseWorkflowStyledComponents.DialogTitle>
            <Form form={formikForm}>
              <CaseWorkflowStyledComponents.DialogContent>
                <GridContainer>
                  <div>
                    <FormBoldTitle required>
                      Date de début
                    </FormBoldTitle>
                    <FormikDatePicker name="dateDebut" />
                  </div>
                  <div>
                    <FormBoldTitle required>
                      Date de fin
                    </FormBoldTitle>
                    <FormikDatePicker name="dateFin" />
                  </div>
                </GridContainer>
                <GridContainer>
                  <div>
                    <FormBoldTitle required>
                      Heure de début
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="dateDebut"
                      time
                    />
                  </div>
                  <div>
                    <FormBoldTitle required>
                      Heure de fin
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="dateFin"
                      time
                    />
                  </div>
                </GridContainer>
                {
                  initialRdv &&
                    <div>
                      <FormBoldTitle required>
                        Motif
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="motif"
                        displayEmpty
                        value={formikForm.values.motif || ''}
                        renderValue={verifySelectFieldValue(formikForm.values.motif)}
                        disabled={isFetchingReasonList}
                      >
                        {
                          reasonList.map((value, index) => (
                            <MenuItem
                              value={value.code}
                              key={`${value.code}-${index}`}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                      <FormBoldTitle required>
                        Commentaire
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Commentaire"
                        name="commentaire"
                        multiline
                        rows={3}
                      />
                    </div>
                }
                {
                  warning &&
                    <StyledAlert
                      variant="outlined"
                      severity="warning"
                    >
                      <StyledAlertTitle>
                        {warning}
                      </StyledAlertTitle>
                    </StyledAlert>
                }
              </CaseWorkflowStyledComponents.DialogContent>
              <CaseWorkflowStyledComponents.DialogAction>
                <LongButton
                  variant="outlined"
                  onClick={onClose}
                >
                  Annuler
                </LongButton>
                <LongButton
                  type="submit"
                  variant="contained"
                  disabled={formikForm.isSubmitting}
                >
                  Valider
                </LongButton>
              </CaseWorkflowStyledComponents.DialogAction>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default CaseWorkflowWorksRdvButton
