/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  onLogoutReset,
  useAuthInfo,
} from 'store/hooks'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'
import {
  useGetLegalDocumentListQuery,
  useGetMessageListQuery,
  useGetMyEstablishementQuery,
} from 'store/api'
import { getRandomInt } from 'helpers/getRandomInt'
import { getChipColor } from 'components/ColoredSquareChip/ColoredSquareChip'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Drawer,
  Link,
} from '@mui/material'
import {
  LogoutRounded,
  Menu,
  Settings,
} from '@mui/icons-material'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import SettingsLayoutNavTabs from 'layouts/SettingsLayout/SettingsLayoutComponents/SettingsLayoutNavTabs'
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import PageContainer from 'layouts/PageContainer/PageContainer'
import CaseLayoutTitleStatus from 'layouts/CaseLayout/CaseLayoutComponents/CaseLayoutTitleStatus'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import GroupLogo from 'components/GroupLogo/GroupLogo'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import MainLayoutNavTabs from './MainLayoutComponents/MainLayoutNavTabs'
import MainLayoutSearchField from './MainLayoutComponents/MainLayoutSearchField'
import GeneralBanner from 'assets/GeneralBanner.png'
import RDFBanner from 'assets/RDFBanner.png'

/* Styled components -------------------------------------------------------- */
const DesktopHeader = styled.div`
  display: initial;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const MobileHeader = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: end;
  overflow-x: overlay;
  overflow-y: hidden;
  z-index: 200;
  background-color: ${(props) => props.theme.colors.main};
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  padding: 10px 0px;
  height: 50px;

  button {
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.desktop} {
    height: 70px;
    // On right side we add an additional 15px because the rest of the outlet
    // layout will have that extra padding for the possible scrollbar width.
    // If we change the scrollbar we may have to change this value
    // The rest of the calcul is to add more padding on large screens, as in PageContainer
    padding: 0px max(calc(15vw - 200px + 15px), calc(0.5rem + 15px)) 0px max(calc(15vw - 200px), 0.5rem);
  }
`

const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1.2rem;

  .main-logo {
    max-width: 140px;
    max-height: 35px;
    margin-right: 15px;
    margin-bottom: 6px;

    @media ${(props) => props.theme.media.desktop} {
      max-height: 50px;
    }
  }
`

const HeaderTitleButton = HeaderTitleContainer.withComponent(Button)

const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    width: min(80%, 400px);
  }
`

const DrawerContent = styled.div`
  padding: 10px;
`

const LinkContainer = styled.div`
  margin: 15px 10px 15px;
`

interface HeaderCardProps {
  addheight: string;
}

const HeaderCard = styled(Card)<HeaderCardProps>`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0px 20px;
  overflow: initial;

  @media ${(props) => props.theme.media.desktop} {
    margin: ${(props) => props.theme.layoutPadding.desktop};
    margin-top: 20px;
    margin-bottom: 0px;
    min-height: ${(props) => props.addheight === 'true' ? '70px' : 'unset'};
  }

  @media ${(props) => props.theme.media.mobile.main} {
    padding: 5px 10px 0px;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    align-items: center;
  }
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;
`

const LargeTitleContainer = styled(LargeTitle)`
  margin-bottom: 0px;
`

const CompanyTitle = styled(LargeTitleContainer)`
  width: 100%;
`

const IconButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const IconContainer = styled.div`
  position: relative;
`

interface CustomUnderlineProps {
  visible: boolean;
}

const CustomUnderline = styled.span<CustomUnderlineProps>`
  position: absolute;
  top: 43px;
  width: ${(props) => props.visible ? '100%' : '0px'};
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${(props) => props.theme.palette.primary.main};
  height: 6px;

  @media ${(props) => props.theme.media.tablet} {
    top: 41px;
  }
`

interface LegalDocumentBannerProps {
  warning: 'error' | 'warning';
}

const LegalDocumentBanner = styled.div<LegalDocumentBannerProps>`
  background-color: ${(props) => props.warning === 'error' ? getChipColor('red', '', props.theme) : getChipColor('darkorange', '', props.theme)};
  display: flex;
  padding: 10px;
  justify-content: center;
`

const Banner = styled.img`
  width: 60%;
  display: none;

  &:hover {
    cursor: pointer;
  }

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  useMobileStatusBarStyle('black')
  const auth = useAuthInfo()
  const location = useLocation()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ openDrawer, setOpenDrawer ] = useState<boolean>(false)

  const { currentData: messages = []} = useGetMessageListQuery({ dossier: caseId }, { skip: !caseId })
  const { currentData: legalDocumentList = []} = useGetLegalDocumentListQuery()
  const { currentData: companyDetails } = useGetMyEstablishementQuery()

  const isMe = (id: string) => id === auth?.idAnnuaire

  useEffect(() => {
    // The following line is meant to change display style when we display the case layout submenus
    const mainLayoutPageContainer = document.querySelector('.main-layout-page-container')

    if (mainLayoutPageContainer?.querySelector('.case-layout-container')) {
      mainLayoutPageContainer.classList.add('display-contents')
    } else {
      mainLayoutPageContainer?.classList.remove('display-contents')
    }
  }, [ location.pathname ])

  const onLogout = () => {
    onLogoutReset()
  }

  const navigateToMainPage = () => {
    navigate('/')
  }

  const navigateToSettings = () => {
    navigate('/parametres')
    setOpenDrawer(false)
  }

  const onVideoClick = () => {
    window.open('https://www.loom.com/share/f7e6ed0b4778496f96fc0acdded1d7ce?sid=6961e7c6-7526-475d-a486-c65772b3cf58', '_blank', 'noreferrer')
  }

  const onDownloadBPUClick = () => {
    const link = document.createElement('a')
    link.href = '/BPU.pdf'
    link.download = 'RéférentielTarifaireSYMABAT-Annexecontratdecourtage.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const isSettings = useMemo(() => location.pathname.indexOf('parametres') !== -1, [ location.pathname ])

  const rand = useMemo(() => getRandomInt(2), [])

  const onBannerClick = () => {
    if (rand === 1) {
      window.open(`https://www.i-ren.fr/decouvrir-iren-pour-la-recherche-de-fuite-symabat?referal=${companyDetails?.siret}`, '_blank')
    } else {
      window.open(`https://www.i-ren.fr/reservez-votre-demonstration-iren-symabat?referal=${companyDetails?.siret}`, '_blank')
    }
  }

  const legalDocumentExpired: 'error' | 'warning' | 'ok' = useMemo(() => {
    const checkDocumentDate = (limit: number) => {
      return legalDocumentList.some((doc) => {
        if (!doc.dateValiditeMax) return false
        const dateValidite = new Date(doc.dateValiditeMax)
        const daysDiff = (dateValidite.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
        return daysDiff <= limit
      })
    }

    if (checkDocumentDate(15)) {
      return 'error'
    }

    if (checkDocumentDate(30)) {
      return 'warning'
    }

    return 'ok'
  }, [ legalDocumentList ])

  return (
    <BaseLayout>
      <ErrorBoundaryPage>
        <DesktopHeader>
          <HeaderContainer>
            <HeaderTitleButton onClick={navigateToMainPage}>
              <GroupLogo />
              Extranet
            </HeaderTitleButton>
            <HeaderTitleContainer>
              <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
            </HeaderTitleContainer>
            <IconButtonContainer>
              <Button
                variant="outlined"
                onClick={onDownloadBPUClick}
              >
                BPU
              </Button>
              <Button
                variant="outlined"
                onClick={onVideoClick}
              >
                Guide Vidéo
              </Button>
              <IconContainer>
                <CustomIconButton
                  Icon={Settings}
                  variant="outlined"
                  onClick={navigateToSettings}
                  label="Paramètres"
                />
                <CustomUnderline visible={isSettings} />
              </IconContainer>
              <IconContainer>
                <CustomIconButton
                  Icon={LogoutRounded}
                  variant="contained"
                  onClick={onLogout}
                  label="Déconnexion"
                />
              </IconContainer>
            </IconButtonContainer>
          </HeaderContainer>
          {
            legalDocumentExpired !== 'ok' &&
              <LegalDocumentBanner warning={legalDocumentExpired}>
                {`⚠️ Un ou plusieurs de vos documents juridiques expirent dans moins de ${legalDocumentExpired === 'error' ? 15 : 30} jours.`}
              </LegalDocumentBanner>
          }
        </DesktopHeader>
        <MobileHeader>
          <HeaderContainer>
            <HeaderTitleButton onClick={navigateToMainPage}>
              <GroupLogo />
              Extranet
            </HeaderTitleButton>
            <HeaderTitleContainer>
              <CustomIconButton
                Icon={Menu}
                color="primary"
                onClick={() => setOpenDrawer(!openDrawer)}
                label="Menu"
              />
            </HeaderTitleContainer>
          </HeaderContainer>
          {
            legalDocumentExpired !== 'ok' &&
              <LegalDocumentBanner warning={legalDocumentExpired}>
                {`⚠️ Un ou plusieurs de vos documents juridiques expirent dans moins de ${legalDocumentExpired === 'error' ? 15 : 30} jours.`}
              </LegalDocumentBanner>
          }
          <DrawerContainer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            anchor="right"
          >
            <DrawerContent>
              <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
              <LinkContainer>
                <Link onClick={onDownloadBPUClick}>
                  BPU
                </Link>
              </LinkContainer>
              <LinkContainer>
                <Link onClick={onVideoClick}>
                  Guide Vidéo
                </Link>
              </LinkContainer>
              <LinkContainer>
                <Link onClick={navigateToSettings}>
                  Paramètres
                </Link>
              </LinkContainer>
              <LinkContainer>
                <Link onClick={onLogout}>
                  Déconnexion
                </Link>
              </LinkContainer>
            </DrawerContent>
          </DrawerContainer>
        </MobileHeader>
        {
          isSettings ?
            <HeaderCard addheight="true">
              <HeaderTitle>
                <CompanyTitle>
                  Mon établissement
                </CompanyTitle>
              </HeaderTitle>
              <SettingsLayoutNavTabs closeDrawer={() => setOpenDrawer(false)} />
            </HeaderCard> :
            <HeaderCard addheight="false">
              <HeaderTitle>
                {
                  caseId ?
                    <CaseLayoutTitleStatus caseId={caseId || ''} /> :
                    <LargeTitleContainer>
                      Tous les dossiers
                    </LargeTitleContainer>
                }
              </HeaderTitle>
              {
                !caseId && (
                  rand === 1 ?
                    <Banner
                      src={RDFBanner}
                      alt="Bannière RDF"
                      onClick={onBannerClick}
                    /> :
                    <Banner
                      src={GeneralBanner}
                      alt="Bannière générale"
                      onClick={onBannerClick}
                    />
                )
              }
              <MainLayoutNavTabs
                messagesLength={messages.filter((msg) => !msg.lu && isMe(msg.destinataire.id)).length}
                closeDrawer={() => setOpenDrawer(false)}
              />
            </HeaderCard>
        }
        <PageContainer className="main-layout-page-container">
          <Outlet />
        </PageContainer>
      </ErrorBoundaryPage>
    </BaseLayout>
  )
}

export default MainLayout
